import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import ArticlesList from '../components/ArticlesList'
import SEO from '../components/SEO'
import TabDescription from '../components/TabDescription'

export const query = graphql`
  {
    mytab: contentfulTabDescription(tabname: { eq: "Services" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }

    mytabPricing: contentfulTabDescription(tabname: { eq: "Pricing" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }

    mytabOffer: contentfulTabDescription(tabname: { eq: "Offer" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }

    article: allContentfulArticle(
      sort: { fields: order, order: DESC }
      filter: { featured: { eq: true } }
    ) {
      nodes {
        featured
        createdWhen
        createdBy
        id
        order
        tags
        title
        image {
          gatsbyImageData
          title
        }
      }
    }
  }
`
const Services = ({
  data: {
    mytab,
    mytabPricing,
    mytabOffer,
    article: { nodes: articles },
  },
}) => {
  return (
    <Layout>
      <SEO
        title='Diensten'
        description='Ontdek de gepersonaliseerde fitnessdiensten en prijsformules van Strong By Sandra.'
        image='/images/services_by_laurieusfit.jpg'
      />
      <main className='page'>
        <section className='services-page'>
          <article>
            <TabDescription {...mytab} />
          </article>
          <article>
            <div className='services-img-container'>
              <StaticImage
                src='../images/services/sandra_by_laurieusfit.jpg'
                alt='Training By LaurieusFit'
                className='services-img'
                placeholder='tracedSVG'
              />
            </div>
          </article>
          <article>
            <TabDescription {...mytabOffer} />
          </article>
          <article>
            <TabDescription {...mytabPricing} />
          </article>
          <article>
            <div className='services-form-container'>
              <form
                action='https://formspree.io/f/xayvpdrz'
                method='POST'
                className='form'
              >
                <h1>Ik hoor graag van jou!</h1>
                <div className='form-row'>
                  <label htmlFor='name'>Je naam</label>
                  <input type='text' name='name' id='name' required />
                </div>
                <div className='form-row'>
                  <label htmlFor='email'>Je email of telefoon</label>
                  <input type='text' name='email' id='email' required />
                </div>
                <div className='form-row'>
                  <label htmlFor='message'>Jouw berichtje</label>
                  <textarea name='message' id='message' />
                </div>
                <div className='form-row'>
                  <button type='submit' className='btn hipster block'>
                    verzenden
                  </button>
                </div>
              </form>
            </div>
          </article>
          <article>
            <div className='services-img-container'>
              <StaticImage
                src='../images/home/mtb_by_laurieusfit.jpg'
                alt='Training By LaurieusFit'
                className='services-img'
                placeholder='tracedSVG'
              />
            </div>
          </article>
        </section>
        <section className='featured-recipes'>
          <h4>Kijk ook eens hier!</h4>
          <ArticlesList articles={articles}></ArticlesList>
        </section>
      </main>
    </Layout>
  )
}

export default Services
